import React, { useEffect, useState } from "react"
import styled from "styled-components";
import { Splide,SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Link } from "react-router-dom";

function Spicy() {
    const[spicy, setSpicy] = useState([]);

    useEffect(() => {
        getSpicy();
    },[]);

const getSpicy = async () => {

    const check = localStorage.getItem('spicy');

    if(check){
        setSpicy(JSON.parse(check));
    } else {
        const api = await fetch(
            `https://api.spoonacular.com/recipes/random?apiKey=${process.env.REACT_APP_API_KEY}&number=9&tags=spicy`);
            const data = await api.json();

            localStorage.setItem('spicy', JSON.stringify(data.recipes));
            setSpicy(data.recipes);
            console.log(data.recipes);
        }
        
    
    };

  return (
    <div>
        <Wrapper>
            <h3>Explore Your Spicy Side</h3>
            <Splide 
                options={{
                    perPage: 4,
                    arrows: false,
                    pagination: false,
                    drag: "free",
                    gap: "5rem",
                }}>
                {spicy.map((recipe) => {
                    return(
                        <SplideSlide key={recipe.id}>
                            <Card>
                                <Link to={'/recipe/' + recipe.id}>
                                    <p>{recipe.title}</p>
                                    <img src={recipe.image} alt={recipe.title} />
                                    <Gradient />
                                </Link>
                            </Card>
                        </SplideSlide>
                    );
                })}
            </Splide>
        </Wrapper>
    </div>
  );
} //end of Spicy func


const Wrapper = styled.div`
    margin: 4rem 0rem;
`


const Card = styled.div`
    min-height: 25rem;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;


    img{
        border-radius: 2rem;
        position:absolute;
        width: 100%;
        height:100%;
        object-fit: cover;
    }


    p{
        position: absolute;
        z-index: 10;
        left: 50%;
        bottom:0%;
        transform: translate(-50%, 0%);
        color: white;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;


const Gradient = styled.div`
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
`;



export default Spicy